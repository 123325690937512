<script setup>

import { getCurrentInstance } from "vue";

const appInstance = getCurrentInstance();
const emitter = appInstance.appContext.config.globalProperties.emitter;

import { store as storeConfig } from "~app_root/public/tv/app.config";

import { Link } from '@inertiajs/vue3'
import { scrollTop } from "@/utility";
import FavoriteButton from "./ProductComponents/FavoriteButton.vue";
import BuyButton from "./ProductComponents/BuyButton.vue";
import QuantityControl from "@/Components/ECart/Reusable/QuantityControl.vue";
import DeleteConfirmation from "@/Components/ECart/Reusable/DeleteConfirmation.vue";
import ReviewsSummary from "@/Components/Product/ReviewsSummary.vue";
import Badges from "@/Components/Product/ProductComponents/Badges.vue";

const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  product_key: {
    type: Number,
    required: true
  }
});

const productTitle = props.product.title.length < 50 ? props.product.title : props.product.title.substring(0, 50) + "...";
const discount = 100 - Math.round(props.product.price_new * 100 / props.product.price_old);

import { useCartItem } from "@/Composables/useCartItem";

const cartItem = useCartItem(props.product.uuid);

const clickedDelete = () => {
  emitter.emit("cartItemDeleteButtonClicked", cartItem.uuid.value);
};

</script>

<template>
  <div class="product-tile" :class="{ 'is-in-cart' : cartItem.quantity.value }">
    <div>

      <FavoriteButton :product_id="product.id"></FavoriteButton>

      <Badges v-if="product.badges?.length" :badges="product.badges"/>

      <button aria-label="Delete" class="delete-product" v-if="storeConfig.add_to_cart_quantity_controls && cartItem.quantity.value" @click="clickedDelete()">
        <i class="lnr icon-trash"></i>
      </button>

      <DeleteConfirmation :item_uuid="cartItem.uuid.value ?? null" />

      <Link :href="route('catchall', product.slug, false)" :alt="product.title" :onFinish="scrollTop">
        <span class="discount-label" :title="'Discount ' + discount + '%'" v-if="product.price_new && product.price_old">-{{ discount }}%</span>
        <div class="product-image-wrapper">
          <img class="product_image" :src="'/storage/product_image' + product.image" alt="">
        </div>
        <div class="product-name-wrapper">
          <p class="product-name" :title="productTitle">{{ productTitle }}</p>
        </div>

        <ReviewsSummary :average_rating="product.average_rating || 0" :total_reviews="product.total_reviews || 0"/>

        <div class="prices d-flex flex-column justify-content-center align-items-center" style="min-height: 40px" v-if="product.price_new">
          <span class="old-price me-2" v-if="product.price_old > product.price_new"> {{ product.price_old.toFixed(2) }} {{ $page.props.store.currency_symbol || $page.props.store.currency_code
            }} </span>
          <span class="price">{{ product.price_new.toFixed(2) }} {{ $page.props.store.currency_symbol || $page.props.store.currency_code
            }}</span>
        </div>
      </Link>

      <div class="bottom-wrapper">
        <div class="product-buttons d-flex justify-content-between mb-3" v-if="storeConfig.product_tile_button_template == 'button' && product.price_new">

          <div class="position-relative" v-if="product.stock">

            <div v-if="storeConfig.add_to_cart_quantity_controls && cartItem.quantity.value">
              <QuantityControl v-model="cartItem.quantity.value" :max="storeConfig.product_max_quantity" />
            </div>

            <BuyButton :product_uuid="product.uuid" :quantity="1" :show_popup="storeConfig.add_to_cart_confirmation_popup" v-else></BuyButton>

          </div>

          <button class="buy-btn flex-fill font-semibold text-white" v-else disabled>
						<span class="cta_text">
							{{ __("out_of_stock") }}
						</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductTile"
};
</script>

<style scoped>

</style>