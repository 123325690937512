// Scroll to the top of the page
import { DateTime } from "luxon";

export function scrollTop(){

    if(window !== undefined){

        const html = document.querySelector('html');

        // Setting inline style of scroll-behavior to 'auto' temporarily
        html.style.scrollBehavior = 'auto'

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'instant' // undocumented option, but works
        })

        // Reverting inline style to empty
        html.style.scrollBehavior = '';

    }

}

/**
 *
 * @param email
 * @returns {RegExpMatchArray}
 */
const validateEmail = (email) => {
    return String(email)
    .toLowerCase()
    .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export { validateEmail }

// Helps generate routes with parameters
const routingHelper = {

    route_name : null,
    params : null,

    getCurrentRoute : function(){

        this.route_name = route().current();
        this.params = route().params;
        return this;

    },

    set : function(key, value){

        // For key p (page) remove the p parameter if page = 1. so instead of products.html?page=1 we will generate products.html
        if(key == 'p' && value == 1){
            delete this.params[key];
        }else{
            this.params[key] = value;
        }

        return this;
    },

    remove : function(keys = []){
        keys.forEach(key => {
            delete this.params[key];
        });
        return this;
    },

    generate : function(){
        return route(this.route_name, this.params);
    }

}

export { routingHelper };


/**
 * Get Translation
 */
export function gt(id){

}

/**
 * Translate strings
 * @param store
 * @returns {function(*, *=): *}
 */
export function translator(store) {

    // Return the translation closure
    const __ = function trans(path, replace = {}) {

        if(path == null)
            return null;

        // convert path to lowercase
        path = path.toLowerCase();

        const keys = path.split('.');

        // Get the store object
        let translation = store.state.translation.translations;

        // Drill down to the translation, to a depth equal to the number of keys divided by the . separator
        for (let i = 0; i < keys.length; i++) {
            translation = translation[keys[i]];
        }

        // Replace placeholders
        for (var placeholder in replace) {
            translation = translation.replace(`:${placeholder}`, replace[placeholder]);
        }

        // Return the translation
        return translation;
    }

    // Make the translation function available globally
    // if window is defined

    if (typeof window !== 'undefined')
        window.__ = __;

    // Return the translation function
    return __;

}

/**
 * Get Config Lang
 * @param locale
 * @returns {function(*): *}
 */
export function getConfigLang(locale){

    const __config = function lang(item){
        return item[locale];
    }

    if (typeof window !== 'undefined')
        window.__config = __config;

    return __config;

}

export function parseDateTime(dateTimeISO8601) {
  return DateTime.fromISO(dateTimeISO8601).toLocaleString(DateTime.DATETIME_MED);
}

// parseDate

export function parseDate(dateTimeISO8601) {
  return DateTime.fromISO(dateTimeISO8601).toLocaleString(DateTime.DATE_MED);
}