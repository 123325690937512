export default {

    namespaced: true,

    state: {
        cart_hash: null,
        items: [],
        items_quantity: 0,
        subtotal: 0,
        authenticated_user_discount: 0,
        voucher_discount: 0,
        voucher: null,
        total: 0,
        shipping_price: null,
        grand_total: 0,
        recently_added_item: null,
        ready: false,
        loading: false,
    },

    getters: {

        voucherDetail(state) {

            if (state.voucher === null)
                return '-';

            if (state.voucher.discount_amount_eur) {
                return '-' + state.voucher.discount_amount_eur + " EUR"
            } else if (state.voucher.discount_amount_ron) {
                return '-' + state.voucher.discount_amount_ron + " RON"
            } else if (state.voucher.discount_amount_percent) {
                return '-' + state.voucher.discount_amount_percent + "%"
            }

        },

        hasInvalidItems(state) {

            return state.items.findIndex(item => {
                return item.valid === 0;
            }) !== -1;

        }

    },

    actions: {

        init({dispatch, state}) {
            dispatch('loadCart')
        },

        reset({commit}) {
            commit('set', emptyCart())
        },

        loadCart({commit}) {

            commit('setLoading', true);

            axios.get(route('cart.get')).then(response => {
                commit('set', response.data);
                commit('set', {
                    ready: true
                });

                commit('setLoading', false);
            })

        },

        destroy({dispatch, commit}) {

            commit('setLoading', true);

            return axios.delete(route('cart.destroy')).then(response => {
                sessionStorage.clear();
                dispatch('reset');
                commit('setLoading', false);
            })

        },

        setShippingMethod({commit}, method) {

            commit('setLoading', true);

            return axios.put(route('cart.set_shipping_method', {
                shipping_method_uuid: method.uuid
            })).then(() => {
                commit('setLoading', false);
            });

        },

        /**
         * Add Item
         * @param product_uuid
         */
        addItem({state, commit, dispatch}, {product_uuid, quantity = 1, show_popup = true}) {

            commit('setLoading', true);

            return axios.post(route('cart.add_item', {
                product_uuid
            }), {
                quantity: quantity
            }).then(response => {

                let {recentlyAddedItem, ...rest} = response.data;

                commit('set', rest);

                if (show_popup) {
                    commit('set', {
                        recently_added_item: recentlyAddedItem
                    });
                }

                commit('setLoading', false);

                // Register Facebook Pixel AddToCart event
                dispatch('registerFbPixelAddToCart', recentlyAddedItem);

            });

        },

        // Register Facebook Pixel AddToCart event
        registerFbPixelAddToCart({state, commit}, item) {

            // Check if Facebook Pixel is loaded
            if (typeof fbq === 'undefined') return;

            // Register Facebook Pixel AddToCart event
            fbq('track', 'AddToCart', {
                content_ids : [item.product_sku],
                content_type : 'product',
                title: item.title,
                price: item.price_new,
                uuid: item.uuid,
            });

        },

        /**
         * Set Item Quantity
         * @param product_uuid
         * @param quantity
         */
        setItemQuantity({state, commit}, {item_uuid, quantity}) {

            commit('setLoading', true);

            axios.put(route('cart.set_quantity', {
                item_uuid
            }), {
                quantity
            }).then(response => {
                commit('set', response.data);
                commit('setLoading', false);
            })

        },


        /**
         * Delete Item
         * @param item_uuid
         */
        deleteItem({state, commit}, item_uuid) {

            commit('setLoading', true);

            let index = state.items.findIndex(item => {
                return item.uuid === item_uuid;
            });

            axios.delete(route('cart.delete_item', {
                item_uuid: item_uuid
            })).then(response => {

                commit('set', response.data);

                commit('setLoading', false);

            });

        },

        // deleteItemByProductUUID

        deleteItemByProductUUID({state, commit}, product_uuid) {

            commit('setLoading', true);

            let index = state.items.findIndex(item => {
                return item.product_uuid === product_uuid;
            });

            axios.delete(route('cart.delete_item_by_product_uuid', {
                product_uuid: product_uuid
            })).then(response => {

                commit('set', response.data);

                commit('setLoading', false);

            });
        },

        deleteInvalidItems({commit, dispatch}) {

            commit('setLoading', true);

            axios.delete(route('cart.delete_invalid_items')).then(response => {
                commit('set', response.data);
                commit('setLoading', false);
            })

        },

        /**
         * Add Voucher
         * @param code
         * @returns {Promise<AxiosResponse<any>>}
         */
        addVoucher({commit, dispatch}, voucher_code) {

            commit('setLoading', true);

            return axios.post(route('voucher.store'), {
                code: voucher_code,
                cart_hash: this.cart_hash
            }).then(response => {
                dispatch('loadCart');
                commit('setLoading', false);
            })

        },

        removeVoucher({commit, dispatch}) {

            commit('setLoading', true);

            return axios.delete(route('voucher.delete')).then(response => {
                dispatch('loadCart');
                commit('setLoading', false);
            })

        }

    },

    mutations: {

        set(state, params) {
            for (let param_name in params) {
                state[param_name] = params[param_name]
            }
        },

        setLoading(state, loading) {
            state.loading = loading
        }

    }

}


const emptyCart = function () {

    return {
        cart_hash: null,
        items: [],
        items_quantity: 0,
        subtotal: 0,
        authenticated_user_discount: 0,
        voucher_discount: 0,
        voucher: null,
        total: 0,
        shipping_price: null,
        grand_total: 0,
    }

}