<template>
	<button aria-label="Adauga la favorite" class="add-to-favorites" :class="{ 'active' : is_favorite }" @click="favorite()">
		<i class="lnr icon-heart"></i>
	</button>
</template>

<script>
export default {
	name: "FavoriteButton",

	data : function(){
		return {
			favorite_product_ids : []
		}
	},

	props : ['product_id'],

	computed : {

		is_favorite(){
			return this.$store.state.favorite.favorite_product_ids.includes(this.product_id);
		}

	},

	mounted(){


	},

	methods : {

		favorite(){

			if(!this.is_favorite){

				this.$store.dispatch('favorite/addFavorite', this.product_id);

			}else{

				this.$store.dispatch('favorite/removeFavorite', this.product_id);

			}

		}

	},


}
</script>

<style scoped>

</style>