import "./bootstrap";

import { createApp, h } from "vue";
import { createInertiaApp, usePage } from "@inertiajs/vue3";

import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

// Import routes from local file
import { Ziggy } from "./ziggy";

if (typeof window !== "undefined" && typeof window.Ziggy === "undefined") {
  window.Ziggy = Ziggy;
}

import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";

import * as Sentry from "@sentry/vue";

import { getConfigLang, translator } from "@/utility";

// Import the mitt event emitter
import mitt from "mitt";
const emitter = mitt();

/*
 * VUEX INITIALIZATION
 */

import { createStore } from "vuex";

// Checkout Module for VUEX store
import threeStepCheckout from "@/Components/ECart/vuex/threeStepCheckoutStore";
import singlePageCheckout from "@/Components/ECart/vuex/singlePageCheckoutStore";
import b2bCheckout from "@/Components/ECart/vuex/b2bCheckoutStore";
import cart from "@/Components/ECart/vuex/cartStore.js";
import central from "@/Components/ECart/vuex/centralStore.js";
import favorite from "@/Components/ECart/vuex/favoriteStore.js";
import translation from "@/Components/ECart/vuex/translationStore.js";

// Generic vuex modules
const selected_modules = {
  central,
  cart,
  favorite,
  translation
};

// Add specific vuex module based on config
import { store as storeConfig } from "~app_root/public/tv/app.config";

const storeMap = {
  "three_step_checkout": threeStepCheckout,
  "one_page_checkout": singlePageCheckout,
  "b2b_checkout": b2bCheckout
};

if (!storeConfig.checkout_type in storeMap)
  throw new Error("Invalid checkout type in config file");

// Add checkout module to selected modules
selected_modules.checkout = storeMap[storeConfig.checkout_type];

const store = createStore({
  modules: selected_modules
});

// Initialize cart store
const init = () => {
  store.dispatch("cart/init");
  store.dispatch("central/init");
  store.dispatch("favorite/init");
};

init();

/*
 * VUEX INITIALIZATION END
 */

// Import Socket Evens Handler for Echo
import SocketEventsHandler from "@/socketEventsHandler";

SocketEventsHandler.setStore(store);

const reset = (reason = null) => {

  let cart_reset_promise = store.dispatch("cart/reset");
  let checkout_reset_promise = store.dispatch("checkout/reset");
  let central_reset_promise = store.dispatch("central/reset");
  let favorite_reset_promise = store.dispatch("favorite/reset");

  sessionStorage.clear();

  return [
    cart_reset_promise,
    checkout_reset_promise,
    central_reset_promise,
    favorite_reset_promise
  ];

};

// Dispatch a custom event via the flash event
document.addEventListener("inertia:success", (evt) => {
  const flashEvent = usePage().props.flash?.event;

  console.log("flashEvent", flashEvent);

  if (flashEvent !== null) {
    document.dispatchEvent(new CustomEvent(flashEvent));
  }
});

// Reset the cart on login/logout/register
["auth.login", "auth.logout", "auth.register"].forEach(event => {

  document.addEventListener(event, function(event) {

    const translations = usePage().props.translations;
    store.commit("translation/setTranslations", translations);

    Promise.all(reset()).then(() => {
      init();
    });
  });

});

// Import Reusable components
import ProductTile from "@/Components/Product/ProductTile.vue";

import { site } from "~app_root/public/tv/app.config";

createInertiaApp({
  title: (title) => `${title} - ${site.name}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
  setup({ el, App, props, plugin }) {

    const createdApp = createApp({ render: () => h(App, props) });

    // Set the emitter to the Vue instance
    createdApp.config.globalProperties.emitter = emitter;

    store.commit("translation/setTranslations", props.initialPage.props.translations);

    createdApp.use(plugin)
    .mixin(
      {
        components: {
          ProductTile
        },
        methods: {
          // Bind the translator to the Vue instance as __ method
          __: translator(store),
          __config: getConfigLang(props.initialPage.props.locale)
        }
      })
    .use(ZiggyVue, Ziggy)
    .use(store)
    .mount(el);

    Echo.channel(`User.${props.initialPage.props.session}`).listen(".custom-event", (evt) => {
      SocketEventsHandler.handle(evt);
    });

    // Initialize Sentry
    const sentry_vue_dsn = import.meta.env.VITE_SENTRY_VUE_DSN;
    if (sentry_vue_dsn) {

      Sentry.init({
        createdApp,

        dsn: sentry_vue_dsn,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.00,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false
        })]

      });

    }

    return createdApp;
  },
  progress : {
    color: "#4B5563",
    includeCSS: false,
    showSpinner: true
  }
});

// Emit an event every second until maxCount is reached. There will be event listeners that act on these events
let counter = 1;
let maxCount = 300; // Stop after 300 seconds
const interval = setInterval(() => {
  emitter.emit("appLoaded:seconds", counter);
  counter++;
  if (counter > maxCount) {
    clearInterval(interval);
  }
}, 1000); // 1000 milliseconds = 1 second

/**
 * Track Page and Send to Google Analytic
 * */
if (import.meta.env.VITE_GA4_ENABLED === "true") {

  // Register a visit to Google Analytics every time a page is visited
  document.addEventListener("inertia:success", (event) => {
    let id = import.meta.env.VITE_GA4_ID;
    gtag("js", new Date());
    gtag("config", id);
  });




}
