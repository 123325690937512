<template>
	<div class="quantity_controls ms-3 me-2">


		<div class="spinner-wrapper" v-if="loading">
			<div class="spinner">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
		</div>

		<template v-else>

			<button class="decrement me-2" @click="decrement" :disabled="modelValue <= min">
				<i class="lnr icon-minus"></i>
			</button>

			<input type="text" :value="modelValue" @keyup="handleKeyUp" @blur="handleBlur"/>

			<button class="increment ms-2" @click="increment" :disabled="modelValue >= max">
				<i class="lnr icon-plus"></i>
			</button>
		</template>

	</div>
</template>

<script>
export default {
	name: "QuantityControl",
	props: {
		modelValue: {
			type: Number,
			default: 1,
		},
		min: {
			type: Number,
			default: 1,
		},
		max: {
			type: Number,
			default: Infinity,
		},
	},
	data() {
		return {
			loading: false,
		}
	},
	emits: ['update:modelValue'],
	methods: {
		increment() {
			if (this.modelValue < this.max) {
				this.showLoading();
				this.$emit("update:modelValue", this.modelValue + 1);
			}
		},
		decrement() {
			if (this.modelValue > this.min) {
				this.showLoading();
				this.$emit("update:modelValue", this.modelValue - 1);
			}
		},
		handleKeyUp(event) {
			if (event.key === 'Enter')
				event.target.blur();
		},
		handleBlur(event) {
			const inputVal = event.target.value;
			const isValid = /^[1-9][0-9]*$/.test(inputVal);

			if (isValid) {

				this.showLoading();

				let newVal = parseInt(inputVal, 10);
				newVal = Math.min(newVal, this.max);
				this.$emit("update:modelValue", newVal);

			} else {
				// Reset to the last valid modelValue
				event.target.value = this.modelValue.toString();
			}
		},

		showLoading() {
			this.loading = true;

			const interval = setInterval(() => {
				if (!this.$store.state.cart.loading) {
					this.loading = false;
					clearInterval(interval);
				}
			}, 300);

		},

	},
};
</script>
