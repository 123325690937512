import { scrollTop } from "@/utility";

export default {

  namespaced: true,

  state: {
    active_step_index: 0,
    shipping_method: null,
    address: null,
    locker: null,
    billing: null,
    billing_same_as_shipping: null,
    payment_method_id: null,
    email: null,
    phone: null,
    observations: null,
    terms: true,
    voucher: null,
    shipping_step_mounted_count: 0,
    billing_step_mounted_count: 0,
    summary_step_mounted_count: 0
  },

  getters: {

    data: state => state.data,

    isBillingValid : state => {

      if (!state.billing)
        return false;

      switch (state.billing.type) {
        case "individual":
          return Boolean(state.billing.first_name && state.billing.last_name && state.billing.country_code && state.billing.state && state.billing.city && state.billing.address);
          break;

        case "company":
          return Boolean(state.billing.company_name && state.billing.unique_identification_number && state.billing.registration_number && state.billing.country_code && state.billing.state && state.billing.city && state.billing.address);
          break;
      }

    }

  },

  actions: {


    reset({ commit }) {
      commit("reset");
    },

    /**
     * Attempts to load data from session storage and then parse it and put it in the store
     * @param state
     * @param commit
     * @param rootState
     */
    loadSessionStorageData({ state, commit, rootState }) {

      // Session storage keys
      let keys = Object.keys(sessionStorage);

      // Loop through keys
      for (let sKey of keys) {

        // If key is not a checkout key, skip
        if (!sKey.includes("checkout_"))
          continue;

        // Get param name
        let param_name = sKey.replace("checkout_", "");
        let data = JSON.parse(sessionStorage.getItem(sKey));

        // Parse data and set it in the store
        switch (data.type) {

          case "string":

            commit("set", {
              key: param_name,
              value: data.value
            });

            // state[param_name] = data.value
            break;

          case "integer":
            commit("set", {
              key: param_name,
              value: parseInt(data.value)
            });
            break;

          case "object":
            commit("set", {
              key: param_name,
              value: data.value
            });

            break;

          case "boolean":

            commit("set", {
              key: param_name,
              value: Boolean(data.value)
            });
            break;

        }

      }

    },

    setStep({ commit }, step = 0) {

      commit("setStep", step);

      sessionStorage.setItem("checkout_active_step_index", JSON.stringify({
        type: "integer",
        value: step
      }));

      // Delay scroll top
      setTimeout(function(e) {
        scrollTop();
      }, 250);

    },

    incrementShippingStepMountedCount({ state, commit }) {
      state.shipping_step_mounted_count++;
    },

    incrementBillingStepMountedCount({ state, commit }) {
      state.billing_step_mounted_count++;
    },

    resetBillingStepMountedCount({ state, commit }) {
      state.billing_step_mounted_count = 0;
    },

    incrementSummaryStepMountedCount({ state, commit }) {
      state.summary_step_mounted_count++;
    }

  },

  mutations: {

    reset(state) {

      state["active_step_index"] = 0;
      state["shipping_method"] = null;
      state["address"] = null;
      state["locker"] = null;
      state["billing"] = null;
      state["billing_same_as_shipping"] = null;
      state["payment_method_id"] = null;
      state["email"] = null;
      state["phone"] = null;
      state["observations"] = null;
      state["terms"] = true;
      state["shipping_step_mounted_count"] = 0;
      state["billing_step_mounted_count"] = 0;
      state["summary_step_mounted_count"] = 0;

    },

    set(state, param) {
      state[param.key] = param.value;
    },

    setProp(state, params) {

      // Set data in store
      state[params.key] = params.value;

      // Set data in local storage
      sessionStorage.setItem("checkout_" + params.key, JSON.stringify({
        type: params.type,
        value: params.value
      }));

    },

    setStep(state, step) {
      state.active_step_index = step;
    },

    setAddress(state, address) {
      state.locker = null;
      state.address = address;
      sessionStorage.setItem("checkout_address", JSON.stringify({
        type: "object",
        value: address
      }));

    },

    /**
     * @param shipping_method
     */
    setShippingMethod(state, shipping_method) {

      state.shipping_method = shipping_method;
      sessionStorage.setItem("checkout_shipping_method", JSON.stringify({
        type: "object",
        value: shipping_method
      }));

    },

    /**
     * @param locker
     */
    setLocker(state, locker) {
      state.address = null;
      state.locker = locker;
      sessionStorage.setItem("checkout_locker", JSON.stringify({
        type: "object",
        value: locker
      }));
    },

    /**
     * @param billing
     */
    setBilling(state, billing) {
      state.billing = billing;
      sessionStorage.setItem("checkout_billing", JSON.stringify({
        type: "object",
        value: billing
      }));

    }

  }


};