function modalData() {
  this.title = null;
  this.image = null;
  this.content = null;
  this
}

export default {

  namespaced: true,

  state: {
    countries: null,
    subscribed_to_newsletter: null,
    modal: new modalData(),
    show_modal: false,
    product_of_the_day: false,
    hide_product_of_the_day: false,
    main_navigation_data: null,
    popups: null
  },

  actions: {

    init({ dispatch, commit }) {

      setTimeout(function() {
        // Get status
        dispatch("getNewsletterSubscriptionStatus");
      }, 500);

    },

    reset({ commit }) {
      commit("set", {
        subscribed_to_newsletter: null
      });
    },

    getNewsletterSubscriptionStatus({ commit }) {

      axios.get(route("newsletter.user-has-subscription")).then(response => {
        commit("set", {
          subscribed_to_newsletter: response.data.is_subscribed
        });
      })
      .catch(error => {
        commit("set", {
          subscribed_to_newsletter: false
        });
      });

    },

    showModal({ commit }, payload) {

      commit("set", {
        modal: payload,
        show_modal: true
      });

    },

    destroyModal({ commit }) {

      commit("set", {
        show_modal: false,
        modal: new modalData()
      });

    },

    getProductOfTheDay({ commit }) {

      axios.get(route("product-of-the-day")).then(response => {
        commit("set", {
          product_of_the_day: response.data.product
        });
      })
      .catch(error => {
        commit("set", {
          product_of_the_day: null
        });
      });

    },

    getCountries({ commit }) {

      return new Promise((resolve, reject) => {

        if (this.state.countries) {
          resolve(this.state.countries);
          return;
        }

        axios.get(route("countries.index")).then(response => {
          commit("set", {
            countries: response.data
          });
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });


      });

    },

    loadMainNavigation({ commit, state }, locale) {

      if (state.main_navigation_data && state.main_navigation_data[locale]) {
        // Data already exists, no need to make a request
        return;
      }

      axios.get(`/storage/main_menu_${locale}.json`).then(r => {
        commit("updateMainNavigationData", {
          locale: locale,
          data: r.data
        });
      });

    }

  },

  mutations: {

    set(state, params) {
      for (let param_name in params) {
        state[param_name] = params[param_name];
      }
    },

    updateMainNavigationData(state, { locale, data }) {
      if (!state.main_navigation_data) {
        // Initialize main_navigation_data if it doesn't exist
        state.main_navigation_data = {};
      }
      // Set the data for the specified locale
      state.main_navigation_data[locale] = data;
    }

  }

};