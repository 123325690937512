import { computed } from 'vue';
import { useStore } from 'vuex';

export function useCartItem(productUuid) {

    const store = useStore();

    const cartStoreItemIndex = computed(() => {
        return store.state.cart.items.findIndex(item => item.product_uuid === productUuid);
    });

    const cart_item_uuid = computed(() => {
        return store.state.cart.items[cartStoreItemIndex.value]?.uuid ?? null;
    });

    const quantity = computed({
        get() {
            return cartStoreItemIndex.value >= 0 ? store.state.cart.items[cartStoreItemIndex.value].quantity : 0;
        },
        set(value) {
            store.dispatch('cart/setItemQuantity', {
                item_uuid : cart_item_uuid.value,
                quantity : parseInt(value)
            });
        }
    });

    return {
        quantity : quantity || null,
        uuid : cart_item_uuid
    };
}
