export default {

    namespaced : true,

    state : {
        address : null,
        billing : null,
        shipping_method : {
            title : 'Curier',
            uuid : 'courier'
        },
        payment_method : 'payment_order'
    },

    mutations : {

        reset(state){
            state.address = null;
            state.billing = null;
        },

        setShipping(state, address){
            state.address = address;
        },

        setBilling(state, billing){
            state.billing = billing;
        },

        setPaymentMethod(state, payment_method){
            state.payment_method = payment_method;
        }

    }

}