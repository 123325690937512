<template>
	<div class="badge" :key="index">

    <img :src="'/images_generic/icons/' + badge.icon" class="icon">

    {{badge.title}}

    <svg
      width="30"
      height="15"
      viewBox="0 0 30 15"
      version="1.1"
      id="label-top"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
      <defs id="defs1"/>
      <g id="label-top-layer">
        <path fill="currentColor" d="M 14.503127,0.02713979 C 13.505971,0.13637271 12.572606,0.57118224 11.84745,1.2642914 L 0,12.587718 V 15 H 30 L 30,12.587718 18.15255,1.2642914 C 17.178449,0.33323443 15.842605,-0.11961561 14.503127,0.02713979 Z m 0.497647,6.79809391 c 1.744507,4.972e-4 3.158343,1.4150328 3.157977,3.1595397 -5.12e-4,1.7438856 -1.414091,3.1574526 -3.157977,3.1579496 -1.74449,3.57e-4 -3.159012,-1.41346 -3.159524,-3.1579496 -3.66e-4,-1.745111 1.414413,-3.159897 3.159524,-3.1595397 z"/>
      </g>
    </svg>

  </div>
</template>

<script>
export default {
	name: "Badge",
	props : ['badge']
}
</script>

<style scoped>

</style>