export default {

    namespaced: true,

    state: {
        favorite_product_ids: [],
    },

    actions: {

        init({dispatch}) {
            dispatch('getFavorites');
        },

        reset({commit}) {
            commit('setFavorites', []);
        },

        getFavorites({commit}) {

            axios.get(route('favorites.ids'))
            .then(response => {
                commit('setFavorites', response.data.ids);
            })
            .catch(error => {
                console.log(error);
            })

        },

        addFavorite({commit}, product_id) {

            axios.post(route('favorites.store', {
                product_id: product_id
            })).then(response => {
                commit('addId', product_id);
            }).catch(error => {
                console.log(error);
            })

        },

        removeFavorite({commit}, product_id) {

            axios.delete(route('favorites.delete', {
                product_id: product_id
            })).then(response => {
                commit('removeId', product_id);
            }).catch(error => {
                console.log(error);
            })

        }

    },

    mutations: {

        setFavorites(state, favorites) {
            state.favorite_product_ids = favorites;
        },

        addId(state, product_id) {
            state.favorite_product_ids.push(product_id);
        },

        removeId(state, product_id) {
            state.favorite_product_ids = state.favorite_product_ids.filter(id => id !== product_id);
        }

    }


}