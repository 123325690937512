export default {

    store: null,

    setStore(store) {
        this.store = store;
    },

    handle(event) {

        console.log('Received event: ' + event.name);

        // Desctructure event object
        const {name, data} = event;

        if (typeof this[name] === "function") {
            this[name](data);
        }
    },

    orderPlaced() {

        setTimeout(() => {
            this.store.dispatch('checkout/reset');
            this.store.dispatch('cart/reset');
            sessionStorage.clear();

            if (window.location.href.includes('checkout')) {
                window.location.href = route('home');
            }
        }, 3000);

    },

    showModal(data) {

        this.store.dispatch('central/showModal', JSON.parse(data));

    }

}


