export default {

    namespaced: true,

    state : {
        translations : {}
    },

    mutations : {

        setTranslations(state, translations){
            state.translations = translations;
        }

    }

}